const DEFAULT_GEOSTYLE = {
  fillColor: '#6A6C6E',
  fillOpacity: 0.5,
  lineColor: '#6A6C6E',
  lineOpacity: 0.5,
  lineWidth: 2,
  circleRadius: 20,
  dash: 'default',
  dashLength: 0,
  gapLength: 0,
  dashArray: '[0, 0]',
};

/**
 *
 * @param {object} geostyle
 */
const dashArray = (geostyle) => {
  let dasharray;
  if (geostyle?.dash === 'default') {
    dasharray = [geostyle?.dashLength || 0, geostyle?.gapLength || 0];
    if (dasharray[0] === 0 && dasharray[1] === 0) {
      dasharray = [];
    }
  } else if (geostyle?.dash === 'array') {
    try {
      const da = JSON.parse(geostyle?.dashArray);
      if (Array.isArray(da)) {
        dasharray = da;
      }
    } catch {
      //
    }
  }
  dasharray = dasharray || [];
  dasharray = dasharray.map((i) => Number(i));
  return dasharray;
};

const validateGeostyle = (geostyle) => {
  if (!geostyle) {
    return DEFAULT_GEOSTYLE;
  }
  const numberFields = ['fillOpacity', 'lineOpacity', 'lineWidth', 'circleRadius'];
  const res = { ...DEFAULT_GEOSTYLE, ...geostyle };

  res.dasharray = dashArray(res);

  Object.keys(res).forEach((key) => {
    if (numberFields.includes(key)) {
      res[key] = Number(res[key]);
    }
  });

  return res;
};

const updatePaint = (paint, geostyle, values) => {
  const gs = validateGeostyle(geostyle);

  const res = paint;
  Object.keys(values).forEach((key) => {
    res[key] = gs[values[key]];
  });

  return paint;
};

const applyGeostyleOnLayer = (layer, geostyle) => {
  const values = {
    circle: {
      'circle-radius': 'circleRadius',
      'circle-color': 'fillColor',
      'circle-opacity': 'fillOpacity',
      'circle-stroke-color': 'lineColor',
      'circle-stroke-opacity': 'lineOpacity',
      'circle-stroke-width': 'lineWidth',
    },
    line: {
      'line-color': 'lineColor',
      'line-opacity': 'lineOpacity',
      'line-width': 'lineWidth',
      'line-dasharray': 'dasharray',
    },
    fill: {
      'fill-color': 'fillColor',
      'fill-outline-color': 'lineColor',
      'fill-opacity': 'fillOpacity',
    },
  };

  const type = Array.isArray(layer) ? 'array' : 'object';

  let layers = [];

  if (Array.isArray(layer)) {
    layers.push(...layer);
  } else {
    layers.push(layer);
  }

  layers = layers.map((l) => {
    const validatedData = l;
    let style;
    if (l.type === 'circle') {
      style = values.circle;
    } else if (l.type === 'line') {
      style = values.line;
    } else if (l.type === 'fill') {
      style = values.fill;
    }
    validatedData.paint = updatePaint(l.paint, geostyle, style);
    return validatedData;
  });

  if (type === 'object') {
    return layers[0];
  }
  return layers;
};

// eslint-disable-next-line import/prefer-default-export
export { applyGeostyleOnLayer };
