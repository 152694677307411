<template>
  <div>
    <div id="map" :style="styleComputed"></div>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { bbox, center } from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import { cmsLayers } from '../../utils';
import { applyGeostyleOnLayer } from '../../utils/map';

export default {
  props: {
    highlightPoint: {
      type: Array,
      default: null,
    },
    geoscript: {
      type: [String, Object],
      default: null,
    },
    fitBounds: {
      type: Boolean,
      default: true,
    },
    geojson: {
      type: Object,
      default: null,
    },
    geostyle: {
      type: [String, Object],
      default: null,
    },
    mapStyle: {
      type: String,
      default: 'mapbox://styles/mapbox/outdoors-v11',
    },
    center: {
      type: Array,
      default: () => [-70.068552, 42.029477],
    },
    zoom: {
      type: Number,
      default: 9,
    },
    borderColor: {
      type: String,
      default: '#6A6C6E',
    },
    fillColor: {
      type: String,
      default: '#6A6C6E',
    },
    height: {
      type: String,
      default: '50vh',
    },
    width: {
      type: String,
      default: '100%',
    },
    point: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      map: null,
      accessToken:
        'pk.eyJ1IjoiY2xhc3NpY25ldXBhbmUiLCJhIjoiY2wyajVycnNvMHVpMDNtbDVucWlmc2t0aiJ9.zJcUa4pULRxTWr-VuPmDnw',
      queue: [],
      geostyleData: null,
      debounce: null,
      layers: {},
      geoscriptLayers: {},
      visibleLayers: [],
      markers: [],
      styleLoaded: false,
    };
  },
  computed: {
    styleComputed() {
      return {
        height: this.height,
        width: this.width,
      };
    },
  },
  watch: {
    highlightPoint: {
      handler: 'renderPoint',
    },
    mapStyle: {
      handler(value) {
        if (this.map && value) {
          this.map.setStyle(value);
        }
      },
    },
    geojson: {
      handler() {
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.render();
        }, 100);
      },
    },
    geostyle: {
      handler() {
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.render();
        }, 100);
      },
      deep: true,
    },
    point: {
      handler() {
        if (this.debounce) {
          clearTimeout(this.debounce);
        }
        this.debounce = setTimeout(() => {
          this.render();
        }, 100);
      },
      deep: true,
    },
  },
  created() {
    Mapbox.accessToken = this.accessToken;
  },
  mounted() {
    this.createMap();
  },
  methods: {
    render() {
      if (!this.map) {
        return;
      }
      const layers = [
        'geojson-fill',
        'geojson-outline',
        'geojson-points',
        'geojson-circle',
        'geojson-line',
      ];
      layers.forEach((layer) => {
        if (this.map.getLayer(layer)) {
          this.map.removeLayer(layer);
        }
      });
      if (this.map.getSource('geojson')) {
        this.map.removeSource('geojson');
      }
      this.addSources();
      this.addLayers(this.geojson);
      this.fitMapBounds();
    },
    getCenter(geojson) {
      if (!geojson) {
        return [-70.068552, 42.029477];
      }
      return center(geojson).geometry.coordinates;
    },

    createMap() {
      const map = new Mapbox.Map({
        container: 'map',
        style: this.mapStyle,
        center: this.center || this.getCenter(this.geojson),
        zoom: this.zoom,
        attributionControl: false,
      });
      this.map = map;

      // on map load
      this.map.on('style.load', () => {
        this.styleLoaded = true;
        this.render();
      });
    },

    fitMapBounds() {
      if (!this.map) {
        return;
      }
      if (this.fitBounds && this.geojson) {
        try {
          this.map.fitBounds(bbox(this.geojson), {
            padding: 20,
          });
        } catch {
          // exit quitely
        }
      }
    },

    addSources() {
      this.map.addSource('geojson', {
        type: 'geojson',
        data: this.geojson,
      });
    },

    renderPoint() {
      if (!this.styleLoaded) {
        return;
      }
      if (!this.map || !this.highlightPoint) {
        return;
      }

      const geostyle = {
        geometry: 'circle',
        fillColor: '#0652EB',
        lineColor: '#027BF5',
        fillOpacity: 0.5,
        lineOpacity: '1',
        lineWidth: 1,
        circleRadius: 7,
      };

      const layers = applyGeostyleOnLayer(cmsLayers('point'), geostyle);

      layers.forEach(({ id }) => {
        if (this.map.getLayer(id)) {
          this.map.removeLayer(id);
        }
      });
      if (this.map.getSource('point')) {
        this.map.removeSource('point');
      }

      this.map.addSource('point', {
        type: 'geojson',

        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Point',
                coordinates: this.highlightPoint,
              },
            },
          ],
        },
      });

      layers.forEach((layer) => {
        this.map.addLayer(layer);
      });
    },

    addLayers() {
      const layers = cmsLayers('geojson', this.geostyleData);
      layers.forEach((layer) => {
        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id);
        }
        this.map.addLayer(layer);
      });
    },
  },
};
</script>

<style>
/* #map {
} */
</style>
